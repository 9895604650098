<template>
    <div class="" style="width:650px;margin:auto;">
        <el-form label-width="210px" ref="vform" :model="form" :rules="rules">
            <el-form-item label="上传营业执照：" prop="license">
                <Upload @change="getimg" :imgUrl="form.license"/>
            </el-form-item>
            <el-form-item label="上传法人/负责人身份证照片：" prop="legal_card_front">
                <div>
                    <div style="color:#FF0000;">要求：请上传10MB以内的 .jpg/gif/bmp/png/jpeg 图片</div>
                    <Upload :accept="'image/*'" @change="getimg1" :uploadSize="true" :imgUrl="form.legal_card_front"/>
                    <div class="t-center" style="width:260px;">证件正面</div>
                </div>
            </el-form-item>
            <el-form-item label="" prop="legal_card_back">
                <Upload @change="getimg2" :accept="'image/*'" :uploadSize="true" :imgUrl="form.legal_card_back"/>
                <div class="t-center" style="width:260px;">证件反面</div>
            </el-form-item>
            <el-form-item label="是否为代办申请：" prop="is_agent">
                <el-radio-group v-model="form.is_agent" text-color="#CC0000">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <div v-if="form.is_agent==1">
                <!-- <el-form-item label="上传代办委托协议：" prop="entrustment_agreement">
                    <Upload @change="getimg3" :imgUrl="form.entrustment_agreement"/>
                </el-form-item> -->
                <el-form-item label="上传代办人身份证照片：" prop="agent_card_front">
                    <div>
                        <div style="color:#FF0000;">要求：请上传10MB以内的 .jpg/gif/bmp/png/jpeg 图片</div>
                        <Upload :accept="'image/*'" @change="getimg4" :uploadSize="true" :imgUrl="form.agent_card_front"/>
                        <div class="t-center" style="width:260px;">证件正面</div>
                    </div>
                </el-form-item>
                <el-form-item label="" prop="agent_card_back">
                    <Upload @change="getimg5" :accept="'image/*'" :uploadSize="true" :imgUrl="form.agent_card_back"/>
                    <div class="t-center" style="width:260px;">证件反面</div>
                </el-form-item>
            </div>
        </el-form>
        <div class="t-center fx_around" style="width:500px;margin:auto">
            <div class="btns1" @click="Previous_step">上一步</div>
            <div class="btns" @click="next_step">下一步</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        var checkfront = (rule, value, callback) => {
            if (this.form.agent_card_front==''||this.form.agent_card_front==undefined) {
                return callback(new Error('请上传代办人身份证正面'));
            }else{
                callback();
            }
        };
        var checkback = (rule, value, callback) => {
            if (this.form.agent_card_back==''||this.form.agent_card_back==undefined) {
                return callback(new Error('请上传代办人身份证反面'));
            }else{
                callback();
            }
        };
        var checkagreement = (rule, value, callback) => {
            if (this.form.entrustment_agreement==''||this.form.entrustment_agreement==undefined) {
                return callback(new Error('请上传代办委托协议'));
            }else{
                callback();
            }
        };
        return {
            Status:[
                {name:'国内身份证',type:'china'},
                {name:'非国内身份证',type:'other'},
            ],
            form:{
                license:'',
                agent_card_front:'',
                agent_card_back:"",
                legal_card_back:'',
                legal_card_front:''
            },
            rules:{
                license:{ required: true, message: "请上传营业执照", trigger: "blur" },
                legal_card_front:{ required: true, message: "请上传法人身份证正面", trigger: "blur" },
                legal_card_back:{ required: true, message: "请上传法人身份证反面", trigger: "blur" },
                is_agent:{required: true, message: "请选择是否为代办申请", trigger: "change"},
                agent_card_front:{ validator: checkfront, trigger: "blur" },
                agent_card_back:{ validator: checkback, trigger: "blur" },
                // entrustment_agreement:{ validator: checkagreement, trigger: "blur" },
            },
        };
    },
    props:{
        forms:{
            default:null,
            type:Object
        }
    },
    watch:{
        forms:{  
            handler:function(val){ 
                if(JSON.stringify(val)!='{}'){
                    this.form = val
                    return
                }
            },  
            immediate:true,//关键
            deep:true
        },
    },
    created() {
        if(localStorage.getItem('twoFrom1')){
            this.form = JSON.parse(localStorage.getItem('twoFrom1'))
        }
    },
    mounted() {

    },
    methods: {
        // changas(val){
        //     if(val==0){
        //         this.rules = {
        //             license:{ required: true, message: "请上传营业执照", trigger: "blur" },
        //             legal_card_front:{ required: true, message: "请上传法人身份证正面", trigger: "blur" },
        //             legal_card_back:{ required: true, message: "请上传法人身份证反面", trigger: "blur" },
        //             is_agent:{required: true, message: "请选择是否为代办申请", trigger: "change"},
        //         }
        //         if(this.form.is_agent==0){
        //             this.form.agent_card_front = ''
        //             this.form.agent_card_back = ''
        //             this.form.entrustment_agreement = ''
        //         }
        //     }else if(val==1){
        //         this.rules = {
        //             license:{ required: true, message: "请上传营业执照", trigger: "blur" },
        //             legal_card_front:{ required: true, message: "请上传法人身份证正面", trigger: "blur" },
        //             legal_card_back:{ required: true, message: "请上传法人身份证反面", trigger: "blur" },
        //             is_agent:{required: true, message: "请选择是否为代办申请", trigger: "change"},
        //             agent_card_front:{ required: true, message: "请上传代办人身份证正面", trigger: "blur" },
        //             agent_card_back:{ required: true, message: "请上传代办人身份证反面", trigger: "blur" },
        //             entrustment_agreement:{ required: true, message: "请上传代办委托协议", trigger: "blur" },
        //         }
        //     }
        // },
        Previous_step(){
            this.$emit('change',0)
        },
        next_step(){
            console.log(this.form)
            this.$refs["vform"].validate((valid) => {
                if (valid) {
                    localStorage.setItem('twoFrom1',JSON.stringify(this.form))
                    this.$emit('change',1)
                }
            });
        },
        getimg(res){
            this.form.license = res
        },
        getimg1(res){
            this.form.legal_card_front = res
        },
        getimg2(res){
            this.form.legal_card_back = res
        },
        getimg3(res){
            this.form.entrustment_agreement = res
        },
        getimg4(res){
            this.form.agent_card_front = res
        },
        getimg5(res){
            this.form.agent_card_back = res
        },
    }
};
</script>

<style scoped lang="scss">
    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    ::v-deep .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    ::v-deep .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 260px;
        height: 160px;
        line-height: 160px;
        text-align: center;
        border-radius: 50%;
    }
    ::v-deep .avatar {
        width: 260px;
        height: 160px;
        display: block;
    }
</style>
