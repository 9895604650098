<template>
    <div class="" style="width:600px;margin:auto;">
        <el-form label-width="200px" :model="form" ref="vform" :rules="rules">
            <el-form-item label="机构名称：" prop="company">
                <el-input v-model="form.company" placeholder="请输入机构名称"></el-input>
            </el-form-item>
            <el-form-item label="法人代表：" prop="legal">
                <el-input v-model="form.legal" placeholder="请输入法人代表"></el-input>
            </el-form-item>
            <el-form-item label="联系方式：" prop="phone">
                <el-input v-model="form.phone" placeholder="请输入法人联系方式"></el-input>
            </el-form-item>
            <el-form-item label="地址：" prop="address">
                <el-input v-model="form.address" placeholder="请输入地址"></el-input>
            </el-form-item>
            <el-form-item label="注册资金：" prop="registered_capital">
                <el-input v-model="form.registered_capital" placeholder="请输入公司注册资金"></el-input>
            </el-form-item>
            <el-form-item label="主营业务：" prop="business">
                <el-input v-model="form.business" placeholder="请输入您公司的主营业务"></el-input>
            </el-form-item>
            <el-form-item label="员工总数：" prop="employee">
                <el-input v-model="form.employee" placeholder="请输入您公司的员工总数"></el-input>
            </el-form-item>
            <el-form-item label="有无办学许可证/信用代码：" prop="credit_code">
                <el-input v-model="form.credit_code" placeholder="请输入有无办学许可证/信用代码"></el-input>
            </el-form-item>
            <el-form-item label="机构地址：" prop="company_address">
                <el-input v-model="form.company_address" placeholder="请输入机构地址"></el-input>
            </el-form-item>
            <el-form-item label="机构邮箱：" prop="company_email">
                <el-input v-model="form.company_email" placeholder="请输入机构邮箱"></el-input>
            </el-form-item>
            <el-form-item label="官方网站：" prop="web_site">
                <el-input v-model="form.web_site" placeholder="请输入官方网站"></el-input>
            </el-form-item>
            <el-form-item label="开设专业：" prop="major">
                <el-input v-model="form.major" placeholder="请输入开设专业"></el-input>
            </el-form-item>
            <el-form-item label="活动范围：" prop="activity">
                <el-input v-model="form.activity" placeholder="请输入活动范围"></el-input>
            </el-form-item>
            <el-form-item label="机构及主要负责人介绍：" prop="desc">
                <el-input type="textarea" :rows="8" resize="none" v-model="form.desc" placeholder="请输入机构主要负责人介绍"></el-input>
            </el-form-item>
        </el-form>
        <div class="t-center fx_around" style="width:500px;margin:auto">
            <div class="btns" @click="next_step">下一步</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form:{},
            rules:{
                company:{ required: true, message: "请输入机构名称", trigger: "blur" },
                legal:{ required: true, message: "请输入法人代表", trigger: "blur" },
                phone:{ required: true, message: "请输入法人联系方式", trigger: "blur" },
                // address:{ required: true, message: "请输入地址", trigger: "blur" },
                // registered_capital:{ required: true, message: "请输入公司注册资金", trigger: "blur" },
                // business:{ required: true, message: "请输入您公司的主营业务", trigger: "blur" },
                // employee_number:{ required: true, message: "请输入您公司的员工总数", trigger: "blur" },
                // credit_code:{ required: true, message: "请输入有无办学许可证", trigger: "blur" },
                // company_address:{ required: true, message: "请输入机构地址", trigger: "blur" },
                // company_email:{ required: true, message: "请输入机构邮箱", trigger: "blur" },
                // web_site:{ required: true, message: "请输入官方网站", trigger: "blur" },
                // major:{ required: true, message: "请输入开设专业", trigger: "blur" },
                // activity:{ required: true, message: "请输入活动范围", trigger: "blur" },
                // desc:{ required: true, message: "请输入机构主要负责人介绍", trigger: "blur" },
            }
        };
    },
    props:{
        forms:{
            default:null,
            type:Object
        }
    },
    watch:{
        forms:{
            handler:function(val){ 
                if(JSON.stringify(val)!='{}'){
                    this.form = val
                    return
                }
            },  
            immediate:true,//关键
            deep:true
        },
    },
    created() {
        if(localStorage.getItem('oneFrom1')){
            this.form = JSON.parse(localStorage.getItem('oneFrom1'))
        }
    },
    mounted() {

    },
    methods: {
        Previous_step(){
            this.$emit('change',0)
        },
        next_step(){
            this.$refs["vform"].validate((valid) => {
                if (valid) {
                    localStorage.setItem('oneFrom1',JSON.stringify(this.form))
                    this.$emit('change',1)
                }
            });
        },
        getFront(res){
            this.form.photo = res
        },
    }
};
</script>

<style scoped lang="scss">
    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    ::v-deep .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    ::v-deep .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 140px;
        line-height: 140px;
        text-align: center;
        border-radius: 50%;
    }
    ::v-deep .avatar {
        width: 100px;
        height: 140px;
        display: block;
    }
</style>
